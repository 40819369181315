import { render, staticRenderFns } from "./__fragment-player.vue?vue&type=template&id=c54151aa&"
import script from "./__fragment-player.vue?vue&type=script&lang=js&"
export * from "./__fragment-player.vue?vue&type=script&lang=js&"
import style0 from "./__fragment-player.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports