<template>
  <div class="v-short-video">
    <p class="second-title">PDM studies the track and selects most viral-potential part, predicting <b>viral-worthy short-video promotion ideas</b> by auto-matching the catchy hook to trending and content-relevant UGC content on TikTok. From a simulation of trending videos curated with your music, get inspired about how to make up-coming release go viral.</p>
    
    <div v-if="version === 'v1'">
      <div class="video-type">
        <p>Video theme: </p>
        <el-radio-group class="video-type-group ml20" v-model="videoType">
          <el-radio-button v-for="type  in videoTypes" :key="type" :label="type">{{ type }}</el-radio-button>
        </el-radio-group>
      </div>
      <div class="video-list">
        <video  
          v-for="(item, index) in videoUrlList" 
          :key="index" 
          :src="item.video_clip_link" 
          @play="handleVideoPlay(index)"
          class="video-item" 
          controls="controls" 
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>

    <div v-if="version === 'v2'">
      <div v-if="catchyHook.length > 0" class="song-promotion">
        <div class="song-promotion-title">{{$t('h.song.promoteTitle1')}}</div>
        <fragment-player
          :startTime="catchyHook[0]" 
          :endTime="catchyHook[1]"
          :mp3Url="songData.audio_link || songData.mp3Url"
          @pausePlay="handleVideoPlay"
          @updateAudioStatus="updateAudioStatus"
          ref="fragment"
        >
        </fragment-player>
        <div class="song-promotion-title">
          {{$t('h.song.promoteTitle2')}}
          <el-tooltip placement="right" content="Short videos for tracks with similarity above 80% would bring the most optimized results.">
            <i class="icon-attention1"></i>
          </el-tooltip>
        </div>
        <div v-if="similarTracks.length > 0">
          <div class="similar-track">
            <p class="pr10">Similar Tracks:</p>
            <div class="similar-select">
              <i 
                class="similar-player"
                @click="playMusic(similarTracks[trackIndex])"
                :class="isAudioPlay && similarTracks[trackIndex].song_link === mp3Url ? 'icon-pause1' : 'icon-play1'"
              ></i> 
              <el-select class="song_promotion_select" v-model="trackIndex">
                <el-option 
                  v-for="(item,key) in similarTracks" 
                  :key="key" 
                  :label="(item.song_name+'    [ Similarity: '+(item.similarity.toFixed(2)*100)+'% ]')" 
                  :value="key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <p class="mb10">Top 3 Short Videos:</p>
          <div class="song-promotion-videos-v2" >
            <div 
              v-for="(video,index) in videoArr" 
              :key="video.video_id"  
              class="video-box-v2"
            >
              <div class="mb10">
                <el-button class="video-button-first" :id="video.video_id+'ori'" @click="handleVideoType(index,video.url,video.video_id,'ori')" >Original Video</el-button>
                <el-button class="video-button-second video-button-active" :id="video.video_id+'syn'" @click="handleVideoType(index,video.video_clip_link,video.video_id,'syn')" style="margin-left:0px">Simulated Video</el-button>
              </div>
              <video
                :src="video.video_clip_link" 
                @play="handleVideoPlay(index)"
                class="song-promotion-video-v2" 
                controls="controls" 
              >
              </video>
            </div>
          </div>
        </div>
        <my-empty v-else :empty-text="$t('h.song.emptyText3')"></my-empty>
      </div>

      <my-empty v-else empty-text="Synthesizing video..."></my-empty>
    </div>
  </div>
</template>

<script>
import FragmentPlayer from './__fragment-player'

export default {
  components: {
    FragmentPlayer
  },

  props: {
    songData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    promotionObj: {
      type: Object,
      default: () => {
        return {}
      }
    },

    version: {
      type: String,
      default: 'v2'
    },

    isAudioPlay: {
      type: Boolean,
      default: false
    }
  }, 

  data () {
    return {
      trackIndex: '',
      similarTracks: [],
      catchyHook: [],
      videoArr: [],
      videoElement: [],
      mp3Url: '',
      videoType: '',
      videoTypes: [],
      promotionData: [],
      videoUrlList: []
    }
  },

  watch: {
    trackIndex (val) {
      this.$emit('handleMusic', {
        url: '',
        status: 'pause'
      })
      this.videoArr = this.similarTracks[val].videos_info
    },

    promotionObj: {
      handler (val) { 
        if (this.version === 'v1') {
          let typeList = []
          this.promotionData = this.promotionObj.promotions
          for (let i in this.promotionData){
            typeList.push(i)
          }
          this.videoTypes = typeList
          this.videoType = typeList[0]
        }
        if (this.version === 'v2') {
          this.similarTracks = val.similar_song
          this.catchyHook = val.point
          if ( this.similarTracks.length > 0) {
            this.trackIndex = 0
          }
        }
      },
      deep: true
    },

    videoType (val) {
      this.videoUrlList = this.promotionData[val]
    },

    isAudioPlay (val) {
      if (val && this.$refs.fragment) {
        this.$refs.fragment.pauseMusic()
        this.handleVideoPlay(999)
      }
      if (!val) {
        this.mp3Url = ''
      }
    }
  },

  mounted () {
    this.videoElement = document.getElementsByTagName('video')
  },

  methods: {
    handleVideoPlay (index) {
      if (this.$refs.fragment) {
        this.$refs.fragment.pauseMusic()
      }
      const videoElement = this.videoElement
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            this.videoElement[i].play()
            this.$emit('handleMusic', {
              url: '',
              status: 'pause'
            })
          } else {
            this.videoElement[i].pause()
          }
        }
      }
    },

    playMusic (obj) {
      this.handleVideoPlay(999)
      if (this.isAudioPlay) {
        this.$emit('handleMusic', {
          url: obj.song_link,
          name:obj.song_name,
          status: 'pause'
        })
      } else {
        this.$emit('handleMusic', {
          url: obj.song_link,
          name:obj.song_name,
          status: 'play'
        })
      }
      this.mp3Url = obj.song_link
    },

    handleVideoType(index,url,id,type){
      const videoElement = this.videoElement
      const buttonElement = document.getElementById(id+type)
      if(type =='ori'){
        const element = document.getElementById(id+'syn')
        element.className ='el-button el-button--default video-button-second'
      }else{
        const element = document.getElementById(id+'ori')
        element.className ='el-button el-button--default video-button-first'
      }
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            buttonElement.className +=' video-button-active'
            videoElement[i].src = url
          } 
        }
      }
    },

    updateAudioStatus (isPlay) {
      if (isPlay) {
        this.$emit('handleMusic', {
          url: '',
          status: 'pause'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-short-video {
  margin-bottom: 100px;

  .second-title {
    font-size: 14px;
    line-height: 28px;
    margin: 10px 0;
  }

  .video-type {
    display: flex;
    align-items: center;
    margin: 10px 0 15px 0;
  }
  .video-type-group {
    flex: 1;
    font-size: 16px;
    margin-top: 5px;
  }
  .video-list{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .video-item{
    width: 260px;
    height: 450px;
    background-color: #eee;
    border-radius: 8px;
    margin: 0 100px 80px 0;
  }
  .video-item:nth-child(3n) {
    margin: 0 0 80px 0;
  }
  
  .song-promotion-title {
    font-size: 16px;
    color: black;
    font-weight: 600;
    margin: 20px 0;
  }
  .song-promotion-videos-v2{
    display: flex;
  }
  .song-promotion-video-v2{
    width: 240px;
    height: 430px;
    margin: 0 auto;
    border-radius: 15px;
    background-color: #000;
    position:relative;
  }
   .search-level ::v-deep .el-input__inner {
    width: 300px;
  }
  .search-level {
    margin-bottom: 10px !important;
  }
  .video-box-v2{
    display:flex;
    flex-flow: column;
    text-align: center;
    margin: 15px 80px 15px 0;
    position:relative;
  }
  .video-box-v2:last-child {
    margin: 15px 0;
  }
  .video-button-first {
    font-size: 12px;
    border-radius:5px 0 0 5px 
  }
  .video-button-second {
    font-size: 12px;
    border-radius: 0 5px 5px 0;
  }
  .video-button-first, .video-button-second {
    padding: 10px 15px !important;
  }
  .video-button-active {
    color: #fff;
    border-color: #ffe8d7;
    background-color: #FF9700;
  }
  .empty-div {
    .my-empty {
      margin:50px auto;
    }
  }
  .empty-box{
    .my-empty{
      margin:120px auto;
    }
  }
  .video-empty {
    width: 70%; 
    margin: 70px auto 0; 
    overflow: hidden; 
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  .short-video {
    margin-bottom: 150px;
  }
  .similar-track {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .similar-select {
    border: 2px solid #FF9700;
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 60px;
  }
  

  .similar-player {
    color: #FF9700;
    font-size: 24px;
    margin-right: 20px;
    cursor: pointer;
  }

  .song_promotion_select {
    width: 500px;
  }
}
::v-deep .el-input .el-input__inner {
  border: none !important;
}
</style>