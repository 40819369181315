<template>
<div class="fragment-player">
    <i v-if="isAudioPlay" class="icon-pause1 pointer" @click="pauseMusic"></i>
    <i v-else class="icon-play1 pointer" @click="playMusic"></i>
    <p class="time">{{ curTime }}</p>

    <!-- 进度条 -->
    <div class="progress-box">
        <div id="fragmentTrack" ref="fragmentTrack" class="progress-track">
            <!-- 总进度 -->
            <div class="play-bar-wrap">
                <!-- 片段 -->
                <div class="fragment-bar" :style="{ left: fragmentLeft + 'px', right: fragmentRight + 'px' }"></div>
            </div>
            <!-- 进度按钮 -->
            <div ref="fragmentThumb" class="progress-thumb" :class="{ 'forbid-transition': thumbSlide }" :style="{ transform: 'translateX(' + thumbTranslateX + 'px)' }"></div>
        </div>
    </div>

    <p class="time">{{ totalTime }}</p>

    <audio id="fragmentPlayer">
      <source :src="mp3Url" />
    </audio>
</div>
</template>

<script>
export default {
    props: {
        mp3Url: {
            type: String
        },
        startTime:{
             type: Number
        },
        endTime:{
            type: Number
        }
    },

    data () {
        return {
            // 片段开始时间
            // startTime: '00:30',
            // 片段结束时间
            // endTime: '',
            // 片段开始点相对左距离
            fragmentLeft: 0,
            // 片段结束点相对右距离
            fragmentRight: 0,
            // 当前播放时间
            curTime: "",
            // 音频总时长
            totalTime: "",
            // 音频是否播放中
            isAudioPlay: false,
            // 音乐播放器
            fragmentPlayer: null,
            // 音频条滑块的位置
            thumbTranslateX: 0, 
            // 进度条滑动时的标记 滑动时为true
            thumbSlide: false
        }
    },

    watch: {
        mp3Url () {
            this.isAudioPlay = false
            this.fragmentPlayer.load()
            setTimeout(() => {
                this.fragmentPlayer.play()
            }, 100)
        },
        isAudioPlay (val) {
            this.$emit('updateAudioStatus', val)
        }
    },

    mounted () {
        this.fragmentPlayer = document.getElementById("fragmentPlayer")
        console.log(this.fragmentPlayer)
        this.isAudioPlay = !this.fragmentPlayer.paused
        this.slideProgress()
        this.audioInit()
        
    },  
    
    methods: {
        // 初始化片段位置
        initFragment () {
            const progressL = this.$refs.fragmentTrack.offsetWidth
            const totalSecond = this.$util.minuteToSecond(this.totalTime)
            const startTime = this.startTime
            let endTime = this.endTime 
            this.fragmentLeft = startTime / totalSecond * progressL
            this.fragmentRight = progressL - endTime  / totalSecond * progressL
            const fragmentPlayer = this.fragmentPlayer
            fragmentPlayer.currentTime = this.startTime
        },

        // 滑动封装
        slideProgress() {
            let fragmentPlayer = this.fragmentPlayer
            let thumb = this.$refs.fragmentThumb
            let _this = this

            thumb.onmousedown = function (e) {
                // 移动时暂停播放并设置transition: none，解决在滑动结束后出现回弹的bug
                fragmentPlayer.pause()
                _this.thumbSlide = true

                // 进度条长度
                let progressL = _this.$refs.fragmentTrack.offsetWidth
                let mouseInitX = e.clientX,
                mouseEndX = 0,
                moveX = 0,
                thumbInitX = _this.$util.stringToNum(thumb.style.transform),
                thumbEndX = 0

                document.onmousemove = function (obj) {
                    mouseEndX = obj.clientX
                    moveX = mouseEndX - mouseInitX

                    let a = thumbInitX - 0 + moveX
                    if (moveX > 0) {
                        thumbEndX = a > progressL ? progressL : a
                    } else {
                        thumbEndX = a <= 0 ? 0 : a
                    }
                    _this.thumbTranslateX = thumbEndX
                }

                document.onmouseup = function () {
                    if (thumbEndX === 0) {
                        thumbEndX = thumbInitX
                    }

                    fragmentPlayer.currentTime = (thumbEndX * fragmentPlayer.duration) / progressL
                    setTimeout(() => {
                        fragmentPlayer.play()
                    })
                    _this.thumbSlide = false

                    document.onmousedown = null
                    document.onmousemove = null
                    document.onmouseup = null
                }
            }
        },

        // 音频初始化
        audioInit() {
            let _this = this
            let fragmentPlayer = this.fragmentPlayer

            // 监听音频可以开始播放,点击播放的时候触发
            fragmentPlayer.addEventListener("play", () => {
                this.isAudioPlay = true
            })

            // 监听音频暂停时的状态
            fragmentPlayer.addEventListener("pause", () => {
                this.isAudioPlay = false
            })

            // 当目前的播放位置已更改时触发事件 播放和调整时触发(进度条相关)
            fragmentPlayer.addEventListener("timeupdate", () => {
                // 获取进度条总长
                let progressLength = _this.$refs.fragmentTrack.offsetWidth
                // 当前播放到的位置（时长）调用封装的秒转字符串方法$util.timeToString
                _this.curTime = _this.$util.timeToString(fragmentPlayer.currentTime)

                // 记录当前播放记录的百分比
                let percent = fragmentPlayer.currentTime / fragmentPlayer.duration || 0

                // 当前进度的按钮位置
                _this.thumbTranslateX = (percent * progressLength).toFixed(3)

                // 播放到片段结束时间，进度按钮返回片段开始时间，音频停止播放
                // const startTime = _this.$util.minuteToSecond(_this.startTime)
                // const endTime = _this.$util.minuteToSecond(_this.endTime)
                 let endTime =  _this.endTime
                const totalTime = fragmentPlayer.duration
                if (endTime <= fragmentPlayer.currentTime || totalTime  <=  fragmentPlayer.currentTime) {
                    console.log('end')
                    fragmentPlayer.currentTime = _this.startTime
                    _this.pauseMusic()
                }
            })

            // 音频播放时长已改变
            fragmentPlayer.addEventListener("durationchange", () => {
                _this.totalTime = _this.$util.timeToString(fragmentPlayer.duration)
                // 初始化片段位置
                _this.initFragment()
            })

            // 音频播放结束 控制播放类型
            fragmentPlayer.addEventListener(
                "ended",
                () => {
                    _this.thumbSlide = false
                    _this.isAudioPlay = false
                },
                true
            )
        },

        // 播放音乐
        playMusic() {
            this.$emit('pausePlay',999);
            this.fragmentPlayer.play()
            this.isAudioPlay = true
        },

        // 暂停音乐
        pauseMusic() {
            if (!this.fragmentPlayer.paused) {
                this.fragmentPlayer.pause()
                this.isAudioPlay = false
            }
        }
    }
}
</script>

<style lang="stylus">
.fragment-player {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    border-radius: 100px;

    i {
        font-size: 26px;
        color: #aaa;
    }

    .time {
        width: 40px;
        text-align: center;
        padding: 0 20px;
    }

    .progress-track {
        width: 480px;
        height: 6px;
        position: relative;
    }

    .play-bar-wrap {
        border-radius: 6px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.04);
    }

    .fragment-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        background: #FF9700;
    }

    .progress-thumb {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid #FF9700;
        top: -3px;
        left: -8px;
        cursor: pointer;
        transition: -webkit-transform 0.5s linear;
        transition: transform 0.5s linear;
        transition: transform 0.5s linear, -webkit-transform 0.5s linear;
    }

    .forbid-transition {
        transition: none !important;
    }
}
</style>