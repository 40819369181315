<template>
  <div class="v-playlist-analysis">
    <p class="second-title">PDM reveals the playlists options for <b>pitching new releases</b>. The listed playlists are selected based on comprehensive consideration of <b>relevancy of content, playlist traffic </b>and<b> track quantity</b> on various streaming services. <br>You can filter the playlists result by draging the scroll bar to adjust range of playlist like counts.</p>

    <!-- 多平台选择 -->
    <el-radio-group v-if="!isAfrotunesReport && !isGospel"  v-model="playlistsCompany">
      <el-radio-button label="boomplay_playlist">Boomplay</el-radio-button>
      <el-radio-button label="spotify_playlist">Spotify</el-radio-button>
      <el-radio-button label="apple_playlist">Apple Music</el-radio-button>
      <el-radio-button label="amazon_playlist">Amazon Music</el-radio-button>
      <el-tooltip content="Coming Soon">
        <el-radio-button disabled label="deezer_playlist">Deezer</el-radio-button>
      </el-tooltip>
    </el-radio-group>

    <!-- 筛选条件 -->
    <div class="filter-box">
      <div v-if="playlistsCompany === 'spotify_playlist'" class="filter-item">
        <span>Like counts:</span>
        <el-slider
          class="slider"
          v-model="likesRange"
          range
          show-stops
          :max="100"
          :marks="marks"
          :format-tooltip="formatTooltip">
        </el-slider>
      </div>
      <div v-if="playlistsCompany !== 'boomplay_playlist'" class="filter-item">
        <span>Theme:</span>
        <el-select v-model="theme">
          <el-option label="All" value="All"></el-option>
          <el-option v-for="(item, index) in platformTags" :key="index" :label="item.type" :value="item.type">
            <div style="display:flex; justify-content: space-between; align-items: center;">
              <p>{{ item.type }}</p>
              <p style="color: #ff8c36; font-size: 13px; border: 1px solid #ff8c36; height: 20px; width: 30px; text-align:center; line-height: 20px; border-radius: 4px;">
                {{ item[`${currentPlatform}_count`] }}
              </p>
            </div>
          </el-option>
        </el-select>
      </div>
      <div v-if="playlistsCompany !== 'boomplay_playlist'" class="filter-item">
        <span>Curated by:</span>
        <el-select v-model="creator">
          <el-option label="All" value="All"></el-option>
          <el-option :label="$util.getUpperCase(currentPlatform)" value="1"></el-option>
          <el-option label="Users" value="0">Users</el-option>
        </el-select>
      </div>
      <div v-if="playlistsCompany === 'boomplay_playlist'" class="filter-item">
        <span>Region:</span>
        <el-select v-model="region">
          <el-option label="All" value="All"></el-option>
          <el-option v-for="item in regionList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div v-if="showPlaylistLevel" class="filter-item">
        <span>Level:</span>
        <el-select v-model="level">
          <el-option label="All" value="All"></el-option>
          <el-option v-for="item in levelList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <el-button v-if="isDSP" class="copy-btn" @click="copyID()">
        Copy Playlist ID
      </el-button>
    </div>

    <!-- 歌单 -->
    <div class="playlist-box">
      <div v-if="playlists.length !== 0" class="playlist-div">
        <div 
          class="playlist-item" 
          v-for="(list) in playlists" 
          :key = list.cover
          @click="gotoPlaylist(list.link)"
        > 
            <div class="img-box">
              <img 
                class="search-img" 
                :src="list.cover"
              >
            </div>
            <div class="info-box">
              <div class="playlist-link flexbox">{{ list.title }}<span v-if="(list.default || list.level === 'Z') && playlistsCompany === 'boomplay_playlist' && isDSP" class="default-icon">Default</span></div>
              <div class="fs12">
                <span v-if="playlistsCompany === 'spotify_playlist'" class='mr30'>Likes: {{ Number(+list.likes).toLocaleString() }} </span>
                <span v-if="playlistsCompany === 'boomplay_playlist'" class='mr30'>Play Counts: {{ Number(+list.play_cnt).toLocaleString() }} </span>
                <span v-if="playlistsCompany !== 'boomplay_playlist'" class='mr30'>Songs: {{ list.song_num }}</span>
                <span v-if="playlistsCompany !== 'boomplay_playlist'" class='mr30'>Theme: {{ list.type }}</span>
                <span v-if="showPlaylistLevel" class='mr30'>Level: {{ list.level === 'Z'? '' : list.level }}</span>
                <span v-if="playlistsCompany === 'boomplay_playlist'" class='mr30'>Region: {{ list.region }}</span>
                <span v-if="playlistsCompany === 'boomplay_playlist'&& isDSP" class='mr30'>Playlist ID: {{ list.link.split('https://www.boomplay.com/playlists/')[1] }}</span>
              </div>
            </div>
        </div>
      </div>
      
      <my-empty
        v-if="playlists.length === 0"
        :empty-text="$t('h.song.emptyText')"
      ></my-empty>

      <el-pagination
        class="pagination-box"
        layout="prev, pager, next"
        :page-size="5"
        :current-page="currentPage"
        :total="totalPlaylist.length"
        @current-change="changePlaylist"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 各平台歌单信息
    platformData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    pdmLevel: {
      type: String,
      default: 'C'
    },
    artistRegion: {
      type: String,
      default: ''
    },
    models:{
      type:Array,
      default: () => {
        return []
      }
    },
    isGospel: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDSP:this.$route.path.includes('/report-d'),
      playlistObj: {},
      currentPlatform: '',
      playlistsCompany:'',
      currentPage: 1,
      totalPlaylist: [],
      playlists:[],
      marks: {
        0: '0',
        25: '1,000',
        50: '10,000',
        75: '100,000',
        100: '1,000,000'
      },
      likesRange: [0, 100],
      platformTags: [],
      theme: 'All',
      creator: 'All',
      region: 'All',
      regionList: [],
      levelList: [],
      level: 'All',
      regionDict:{
        'GH':'Ghana',
        'KE':'Kenya',
        'NG':'Nigeria',
        'ZM':'Zambia',
        'UG':'Uganda'
      }
    }
  },

  computed: {
    isAfrotunesReport () {
      return this.$route.path.includes('report-')
    },
    
    showPlaylistLevel () {
      return this.playlistsCompany === 'boomplay_playlist' && (!this.isAfrotunesReport || this.$route.path.includes('report-d'))
    }
  },

  watch: {
    platformData: {
      handler () {
        this.handlePlaylist()
      },
      deep: true
    },

    likesRange () {
      this.filterPlaylist()
    },

    theme () {
      this.filterPlaylist()
    },

    creator () {
      this.filterPlaylist()
    },

    playlistsCompany (val) {
      if (val === 'boomplay_playlist') {
        this.marks = {
          0: '0',
          25: '100',
          50: '1,000',
          75: '10,000',
          100: '100,000'
        }
      } else {
        this.marks = {
          0: '0',
          25: '1,000',
          50: '10,000',
          75: '100,000',
          100: '1,000,000'
        }
      }
      // 切换平台时，对主题、创作者、地区、点赞量范围进行初始化
      this.theme = 'All'
      this.creator = 'All'
      this.region = 'All'
      this.likesRange = [0, 100]
      this.currentPlatform = val.split('_')[0] || ''
      this.platformTags = this.playlistObj.playlist_tags.filter(item => item[`${this.currentPlatform}_count`])
      this.filterPlaylist()
    },

    region () {
      this.filterPlaylist()
    },

    level () {
      this.filterPlaylist()
    }
  },

  methods: {
    formatTooltip (value) {
      const max = this.playlistsCompany === 'boomplay_playlist' ? 100 : 1000
      if (value >= 0 && value <=25) {
        return Math.ceil(value*max/25)
      }
      if (value > 25 && value <=50) {
        return Math.ceil((value-25)*9*max/25 + max)
      }
      if (value > 50 && value <=75) {
        return Math.ceil((value-50)*90*max/25 + 10*max)
      }
      if (value > 75 && value <=100) {
        return Math.ceil((value-75)*900*max/25 + 100*max)
      }
    },

    gotoPlaylist(link) {
      window.open(link)
    },

    changePlaylist (currentPage) {
      this.currentPage = currentPage
      const page = currentPage - 1
      this.playlists = this.totalPlaylist.slice(page*5, page*5 + 5)
    },
    copyID(){
      let idList=[]
      this.totalPlaylist.forEach(item=>{
        idList.push(item.link.split('https://www.boomplay.com/playlists/')[1])
      })
      let input = document.createElement('textarea')
      input.value = idList.join(',')
      input.setAttribute('readonly', '')
      document.body.appendChild(input)
      input.select()
      if (document.execCommand('copy')) {
          document.execCommand('copy')
          this.$message({
              message: 'Copy Success!',
              type: "success",
              offset: 100
          })
      }
      document.body.removeChild(input)
    },
    filterPlaylist () {
      // 过滤出符合条件的歌单
      const playlist = this.playlistObj[this.playlistsCompany] || []
      if(this.artistRegion){
        let list = playlist.filter(item => this.selectPlaylist(item))
        let regionlist =[]
        let normallist =[]
        list.forEach((item)=>{
          if(item.region=== this.regionDict[this.artistRegion]){
            regionlist.push(item)
          }else{
            normallist.push(item)
          }
        })
        this.totalPlaylist = [...regionlist,...normallist]
      }else{
        this.totalPlaylist = playlist.filter(item => this.selectPlaylist(item))
      }
      // 回到第一页
      this.changePlaylist(1)
    },

    selectPlaylist (item) {
      // 过滤歌单类型
      if (this.theme !== 'All' && this.theme !== item.type) return false
      // 过滤创建者类型
      if (this.creator !== 'All' && this.creator !== item.official) return false
      // 过滤地区
      if (this.playlistsCompany === 'boomplay_playlist' && this.region !== 'All' && this.region !== item.region) return false
      // 过滤歌单等级
      if (this.playlistsCompany === 'boomplay_playlist' && this.level !== 'All' && this.level !== item.level) return false
      // 过滤平台
      if (this.playlistsCompany !== 'spotify_playlist' && this.playlistsCompany !== 'boomplay_playlist') return true
      // 过滤likes range
      const min = this.formatTooltip(this.likesRange[0])
      const max = this.formatTooltip(this.likesRange[1])
      if (min > +item.likes || (+item.likes > max && max < 100000)) return false
      return true
    },

    sortBy (a, b) {
      const levelList = {
        'S': 4,
        'A': 3,
        'B': 2,
        'C': 1,
        'Z': 0
      }
      const num = levelList[b.level] - levelList[a.level]
      if (num !== 0) {
        return num
      } else {
        return b.similarity - a.similarity
      }
    },

    handleBoomplaylist () {
      const level = this.pdmLevel
      const list = this.playlistObj.boomplay_playlist || []
      // 先根据等级SABC、相似度进行排序
      list.sort((a, b) => this.sortBy(a,b))
      const Slist = []
      const Alist = []
      const Blist = []
      const Clist = []
      const Zlist = []
      for (let i = 0; i < list.length; i++) {
        // 获取对应国家的相似度top5垫底歌单
        // 若无歌手国家，则直接取相似度top5垫底歌单，不考虑歌单所属国家
        if ((list[i].default || list[i].level === 'Z') && (list[i].region === this.regionDict[this.artistRegion] || !this.artistRegion) && Zlist.length < 10) {
          Zlist.push(list[i])
        }
        if (!list[i].default) {
          if (level === 'S') {
            if (list[i].level === 'S' && Slist.length < 10) {
              Slist.push(list[i])
            }
            if (list[i].level === 'A' && Alist.length < 10) {
              Alist.push(list[i])
            }
            if (list[i].level === 'B' && Blist.length < 5) {
              Blist.push(list[i])
            }
          }
          if (level === 'A') {
            if (list[i].level === 'A' && Alist.length < 10) {
              Alist.push(list[i])
            }
            if (list[i].level === 'B' && Blist.length < 15) {
              Blist.push(list[i])
            }
          }
          if (level === 'B' && list[i].level === 'B' && Blist.length < 15) {
            Blist.push(list[i])
          }
          if (level === 'C' && list[i].level === 'C' && Clist.length < 15) {
            Clist.push(list[i])
          }
        }
      }
      Slist.sort((a, b) => b.play_cnt - a.play_cnt)
      Alist.sort((a, b) => b.play_cnt - a.play_cnt)
      Blist.sort((a, b) => b.play_cnt - a.play_cnt)
      Clist.sort((a, b) => b.play_cnt - a.play_cnt)
      Zlist.sort((a, b) => b.play_cnt - a.play_cnt)
      this.playlistObj.boomplay_playlist = [...Slist, ...Alist, ...Blist, ...Clist,...Zlist]

    },

    handlePlaylist() {
      this.playlistObj = JSON.parse(JSON.stringify(this.platformData))
      // 歌单标签
      this.platformTags = this.playlistObj.playlist_tags.filter(item => item.spotify_count)
      
      if (this.playlistObj.boomplay_playlist && this.playlistObj.boomplay_playlist.length > 0) {
        // 打分模型为Nigeria时 根据打分结果等级进行歌单过滤处理
        if(this.models.indexOf('af_west_ng_v1')!==-1){
          this.handleBoomplaylist()
        }
        const set = new Set()
        const levelSet = new Set()
        const bp_playlist = this.playlistObj.boomplay_playlist || []
        bp_playlist.forEach(item => {
          set.add(item.region)
          levelSet.add(item.level)
        })
        this.regionList = Array.from(set)
        this.levelList = Array.from(levelSet)
      }
      this.playlistsCompany = 'boomplay_playlist'
      
    }
  }
}
</script>

<style lang="stylus" scoped>
.v-playlist-analysis {
  .second-title {
    font-size: 14px;
    line-height: 28px;
    margin: 10px 0;
  }
  
  .playlist-box {
    flex: 1;

    .playlist-div {
      height: 520px;
    }

    .playlist-item{
      display: flex
      font-size: 13px;
      height: 80px;
      line-height: 30px;
      padding: 12px 0;
      border-bottom: 1px solid #dedede;
      cursor: pointer;
    }
    .playlist-item:last-child {
      border: none;
    }
    .playlist-item:hover {
      background: #fff4eb;
      .playlist-link {
        color: #FF9700;
      }
    }
    .img-box { 
      width: 80px;
      height: 80px;
      position: relative;
      margin: 0 30px 0 10px;
      .search-img {
        width: 80px;
        height: 80px;
      }
    }
    .info-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .playlist-link {
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
  }
  .player-icon {
    font-size: 30px;
    color: #b3b3b3;
    cursor: pointer;
  }
  p {
    word-break: normal;
  }

  .filter-box {
    margin: 20px 0 30px;
    display: flex;
    .copy-btn{
      background: #ff8c36;
      margin-left: 40px;
      color: #fff;
    }
    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      span {
        padding-right: 10px;
      }
      .slider {
        width: 340px;
        margin: 0 20px 0 10px;
      }
      .el-select {
        width: 180px;
      }
    }
    .filter-item:last-child {
      margin-right: 0;
    }
  }
  .default-icon {
    font-size:12px;
    color: #FF9700;
    font-weight: 200;
    border: 0.5px solid #ff8c36;
    border-radius: 3px;
    background: #fff4eb;
    text-align: center;
    width: 50px;
    height:22px;
    line-height: 22px;
    margin-left: 10px;
  }
  .pagination-box {
    text-align: center;
    margin-top: 20px;
  }
}
::v-deep .el-pagination {
  .btn-prev, .btn-next, .el-pager .number {
    border: 1px solid #ddd;
    border-radius: 2px;
    margin: 0 5px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
  }

  .el-pager .active  {
    border: 1.5px solid #FF9700;
  }
}
</style>
