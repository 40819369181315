<template>
  <div class="v-audience-profile">
    <p class="second-title"><b>Predict target audience</b> of both up-coming releases and released songs even if no or very few listener engagement data. The prediction is based on AI analysis of  <b>artist data</b>, <b>music content (audio&lyrics)</b> and <b>track-down of changing music trends</b>  around the world. Determine which market best fits the new release and optimize promotion strategies ahead of time. <br> You can filter the countries and regions result on the map by draging the scroll bar to adjust range of score.</p>
    <div class="map-chart" id="map"></div>

    <div class="top30-region">
      <p class="top30-text">Top30 Regions</p>
      <div class="top-10">
        <div v-for="item in region10" :key="item.name">
          {{ item.name }}
        </div>
      </div>
      <div class="top-20">
        <div v-for="item in region20" :key="item.name">
          {{ item.name }}
        </div>
      </div>
      <div class="top-30">
        <div v-for="item in region30" :key="item.name">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/graph"
import "echarts/map/js/world.js"
import "echarts/lib/component/tooltip";
import mapData from './world.json';

export default {
  props: {
    regionData: {
      type: Array,
      default: () => {
        return []
      }
    },
    pdmLevel: {
      type: String,
      default: 'C'
    },
    artistRegion: {
      type: String,
      default: ''
    },
    models:{
      type:Array,
      default: () => {
        return []
      }
    }
  },

  data () {
    return {
      mapChart: null,
      region10: [],
      region20: [],
      region30: [],
      worldmapData: [],
      regionDict: {
        'GH':'Ghana',
        'KE':'Kenya',
        'NG':'Nigeria',
        'ZM':'Zambia',
        'UG':'Uganda'
      }
    }
  },

  computed: {
    userType () {
      return this.$store.state.user.usertype
    },
    isAfrotunesReport () {
      return this.$route.path.includes('report-a')
    },
    canShow () {
      return this.userType === 'afrotunes' || this.isAfrotunesReport
    }
  },

  watch: {
    regionData: {
      handler () { 
        this.handleData()
      },
      deep: true
    }
  },

  mounted () {
    if (this.regionData.length !== 0) {
      this.handleData()
    }
  },

  methods: {
    handleData () {
      // 世界地图 Echart
      let map = []
      let data = this.regionData
      for(let i in data){
        let a ={}
        a.name = data[i][0]
        a.value = data[i][1]
        map.push(a)
      }
      this.worldmapData = map.sort((a,b) => b.value - a.value)
      this.worldmapData.sort((a,b) => b.value - a.value)
      let sortArr = JSON.parse(JSON.stringify(this.worldmapData))
      // 歌手国籍为Ghana\Kenya\Nigeria\Zambia\Uganda其中之一时，该国籍至少排进海外受众地区Top20。
      const region = this.artistRegion
      if(region in this.regionDict){
        const index = sortArr.findIndex(item => item.name === this.regionDict[region])
        if (index > 19) {
          sortArr[index].value = sortArr[19].value
          sortArr.sort((a,b) => b.value - a.value)
        }
      }
      // 是Nigeria模型的情况下
      if (this.models.indexOf('af_west_ng_v1')!==-1) {
        // 歌曲用Nigeria模型打分为S或A等级时，Nigeria至少排进海外受众地区Top10；
        // 歌曲用Nigeria模型打分为B等级时，Nigeria至少排进海外受众地区Top20。
        const model = 'Nigeria'
        const level = this.pdmLevel
        if(level == 'S' || level == 'A' ){
          const index = sortArr.slice(0, 30).findIndex(item => item.name === model)
          if (index === -1) {
            sortArr.splice(10,0,{name: model, value: sortArr[9].value})
          }
        } else if(level == 'B'){
          const index = sortArr.slice(0, 30).findIndex(item => item.name === model)
          if (index === -1) {
            sortArr.splice(20,0,{name: model, value: sortArr[19].value})
          }
        }
      }
      this.region10 = sortArr.slice(0, 10)
      this.region20 = sortArr.slice(10, 20)
      this.region30 = sortArr.slice(20, 30)
      this.worldmapData = sortArr
      this.drawMap()
    },

    drawMap(){
      // 基于准备好的dom，初始化echarts实例
      let chart = echarts.init(document.getElementById('map'))
      // 监听屏幕变化自动缩放图表
      window.addEventListener('resize', function () {
        chart.resize()
      })
      // 绘制图表
      chart.setOption({
        grid: {
          width:'100%',
          height:'100%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        // 提示框组件
        tooltip: {
          trigger: 'item', // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          // 使用函数模板  传入的数据值 -> value: number | Array
          formatter: function (val) {
            if(val.data == null) return 
            return val.data.name + ': ' + val.data.value + '%'
          }
        },
        // 视觉映射组件
        visualMap: {
          show: true,
          type: "continuous",
          min: 0,
          max: 100,
          text: [
            "High",
            "Low"
          ],
          textStyle: {
            color: "#ddd"
          },
          inRange: {
            color: [
              "#fff5e5",
              "#ffcb80",
              "#FF9700"
            ]
          },
          calculable: true,
          inverse: false,
          splitNumber: 5,
          dimension: 0,
          orient: "vertical",
          top: "center",
          showLabel: true,
          itemWidth: 20,
          itemHeight: 140,
          borderWidth: 0
        },
        series: [
          {
            type: 'map', // 类型
            // 系列名称，用于tooltip的显示，legend 的图例筛选 在 setOption 更新数据和配置项时用于指定对应的系列
            name: 'world map',
            mapType: 'world', // 地图类型
            // 图形上的文本标签
            label: {
              show: false, // 是否显示对应地名
              position: "top",
              margin: 8
            },
            // 地图区域的多边形 图形样式
            itemStyle: {
              emphasis: {
                label: {
                  show: null
                },
                areaColor: "#FF9700"
              }
            },
            emphasis: {},
            roam: false,
            aspectScale: 0.8,
            nameProperty: "name",
            selectedMode: false,
            zoom: 1.2,
            mapValueCalculation: "sum",
            showLegendSymbol: false,
            rippleEffect: {
              show: true,
              brushType: "stroke",
              scale: 2.5,
              period: 4
            },
            // 自定义地区的名称映射
            nameMap: mapData.namemap,
            // 地图系列中的数据内容数组 数组项可以为单个数值
            data: this.worldmapData
          }
        ]
      })
      this.mapChart = chart
    }
  }
}
</script>

<style lang="stylus">
.v-audience-profile {
  width: 100%;
  overflow: hidden;

  .second-title {
    font-size: 14px;
    line-height: 28px;
    margin: 10px 0;
  }
  
  .map-chart {
    width: 100%;
    height: 400px;
    margin: 20px auto;
  }

  .top30-region {
    padding: 20px 100px 0 20px;
  }

  .top30-text {
    font-size: 20px;
    font-family: 'Gilroy Bold';
    font-weight: bold;
    color: rgba(0,0,0,0.85);
    margin-bottom: 20px;
    text-align: left;
  }
  
  .top-10, .top-20, .top-30 {
    display: flex;
    flex-wrap: wrap;

    div {
      border: 1px solid;
      border-radius: 50px;
      padding: 5px 15px;
      margin: 0 10px 14px 0;
    }
  }

  .top-10 div {
    border: 2px solid #D6411D;
    color: #D6411D;
  }

  .top-20 div {
    border: 2px solid #FF9700;
    color: #FF9700;
  }

  .top-30 div {
    border: 2px solid #A0A0A0;
    color: #A0A0A0;
  }
}
</style>